<template>
  <q-toolbar
    :class="{ 'q-pt-lg': !drawerView }"
    class="justify-between q-pb-lg"
  >
    <q-btn
      :aria-label="t('pagination.button.label.prev')"
      class="stroke-primary"
      :disable="!hasPrevPage"
      :icon="t('icons.handLeft')"
      no-caps
      no-wrap
      ref="prevMotionRef"
      :size="buttonSize"
      stretch
      :to="toPrevRoute()"
      @click="onClick()"
      @mouseenter="onPrevMouseEnter()"
      @mouseleave="onPrevMouseLeave()"
    />
    <toolbar-results-size class="q-ml-md" type="collection" />
    <q-btn
      :aria-label="t('pagination.button.label.next')"
      class="stroke-primary"
      :disable="!hasNextPage"
      :icon-right="t('icons.handRight')"
      no-caps
      no-wrap
      ref="nextMotionRef"
      :size="buttonSize"
      stretch
      :to="toNextRoute()"
      @click="onClick()"
      @mouseenter="onNextMouseEnter()"
      @mouseleave="onNextMouseLeave()"
    />
  </q-toolbar>
</template>

<script setup>
import { computed, inject, nextTick, ref, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { useMotion } from "@vueuse/motion";
import { storeToRefs } from "pinia";
import { i18n } from "src/boot/i18n";
import { useCollectionStore } from "src/stores/collection";
import { usePreferencesStore } from "src/stores/preferences";
import ToolbarResultsSize from "src/components/toolbar/ToolbarResultsSize.vue";

defineOptions({ name: "CollectionPagination" });

const props = defineProps({
  buttonSize: { type: String, default: "32px" },
  currentPage: { type: Number, default: 1 },
  view: { type: String, default: "default" },
});

const bus = inject("bus");
const { t } = i18n.global;
const router = useRouter();
const collectionStore = useCollectionStore();
const { cursorNext, cursorPrev, hasNextPage, hasPrevPage } =
  storeToRefs(collectionStore);
const preferencesStore = usePreferencesStore();
const { reducedMotion } = storeToRefs(preferencesStore);

const computedReducedMotion = computed(() => reducedMotion.value);
const drawerView = computed(() => props.view === "drawer");

const prevMotionRef = ref(null);
const nextMotionRef = ref(null);

let prevMotionInstance = null;
let nextMotionInstance = null;

const createMotionInstance = (motionRef) => {
  return useMotion(motionRef, {
    initial: {
      y: 100,
      opacity: 0,
    },
    enter: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 350,
        damping: 20,
        delay: 50,
        onComplete: () => {
          prevMotionInstance.apply("levitate");
          nextMotionInstance.apply("levitate");
        },
      },
    },
    hover: {
      scale: 1.1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
    leave: {
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    },
    levitate: {
      y: 5,
      transition: {
        duration: 2000,
        repeat: Infinity,
        ease: "easeInOut",
        repeatType: "mirror",
      },
    },
  });
};

const applyAnimations = () => {
  if (
    !computedReducedMotion.value &&
    prevMotionInstance &&
    nextMotionInstance
  ) {
    prevMotionInstance.apply("enter");
    nextMotionInstance.apply("enter");
  } else {
    prevMotionInstance.stop();
    nextMotionInstance.stop();
  }
};

const onClick = () => {
  bus.emit("showHideSounds");
};

const onPrevMouseEnter = () => {
  if (!computedReducedMotion.value && prevMotionInstance && props.hoverable) {
    prevMotionInstance.apply("hover");
  }
};

const onPrevMouseLeave = () => {
  if (!computedReducedMotion.value && prevMotionInstance && props.hoverable) {
    prevMotionInstance.apply("leave");
  }
};

const onNextMouseEnter = () => {
  if (!computedReducedMotion.value && nextMotionInstance && props.hoverable) {
    nextMotionInstance.apply("hover");
  }
};

const onNextMouseLeave = () => {
  if (!computedReducedMotion.value && nextMotionInstance && props.hoverable) {
    nextMotionInstance.apply("leave");
  }
};

const toNextRoute = () => {
  const currentQuery = { ...router.currentRoute.value.query };
  delete currentQuery.before;
  delete currentQuery.last;

  return {
    name: router.currentRoute.value.name,
    params: { ...router.currentRoute.value.params },
    query: {
      ...currentQuery,
      after: cursorNext.value,
    },
  };
};

const toPrevRoute = () => {
  const currentQuery = { ...router.currentRoute.value.query };
  delete currentQuery.after;
  delete currentQuery.first;

  return {
    name: router.currentRoute.value.name,
    params: { ...router.currentRoute.value.params },
    query: {
      ...currentQuery,
      before: cursorPrev.value,
    },
  };
};

watch(computedReducedMotion, () => {
  if (!computedReducedMotion.value) {
    prevMotionInstance = createMotionInstance(prevMotionRef);
    nextMotionInstance = createMotionInstance(nextMotionRef);
    applyAnimations();
  } else {
    if (prevMotionInstance) prevMotionInstance.stop();
    if (nextMotionInstance) nextMotionInstance.stop();
  }
});

onMounted(async () => {
  await nextTick();
  if (!computedReducedMotion.value) {
    prevMotionInstance = createMotionInstance(prevMotionRef);
    nextMotionInstance = createMotionInstance(nextMotionRef);
    applyAnimations();
  }
});
</script>
