import gql from "graphql-tag";

export const basicCollection = gql`
  fragment BasicCollection on Collection {
    description
    descriptionHtml
    handle
    id
    title
  }
`;

export const collectionImage = gql`
  fragment CollectionImage on Collection {
    image {
      ...ImageFullscreen
    }
  }
`;

export const collectionImageCard = gql`
  fragment CollectionImageCard on Collection {
    image {
      ...ImageCard
    }
  }
`;

export const collectionMeta = gql`
  fragment CollectionMeta on Collection {
    seo {
      title
      description
    }
    metaSeo: metafield(namespace: "global", key: "seo") {
      value
    }
    metaDescription: metafield(namespace: "global", key: "description_tag") {
      value
    }
    metaTitle: metafield(namespace: "global", key: "title_tag") {
      value
    }
  }
`;
