<template>
  <q-page>
    <content-banner
      :body="data?.description"
      :title="data?.title"
      :view="view"
    />
    <content-grid
      v-if="data.products?.edges"
      :content="data.products.edges"
      :gutter="$q.screen.gt.sm ? 'lg' : 'md'"
      type="collection"
      :view="view"
    />
    <div class="relative-position">
      <blur-background />
      <collection-pagination :view="view" />
    </div>
  </q-page>
</template>

<script setup>
import { computed, nextTick, onMounted, watch } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useMeta } from "quasar";
import { keepPreviousData, useQuery } from "@tanstack/vue-query";
import { queryCollectionByHandle } from "src/api/shopify/queries/collection";
import { i18n } from "src/boot/i18n";
import { queryClient } from "src/boot/vueQuery";
import { useDehydrateQueryClient } from "src/composables/query/useDehydrateQueryClient";
import { useHydrateQueryClient } from "src/composables/query/useHydrateQueryClient";
import { useQueryErrorHandler } from "src/composables/query/useQueryErrorHandler";
import { useUpdateStoreFromQuery } from "src/composables/query/useUpdateStoreFromQuery";
import { getQueryKey } from "src/utils/global";
import { useAuraStore } from "src/stores/aura";
import { useCollectionStore } from "src/stores/collection";
import BlurBackground from "src/components/blur/BlurBackground.vue";
import CollectionPagination from "src/components/collection/CollectionPagination.vue";
import ContentBanner from "src/components/content/ContentBanner.vue";
import ContentGrid from "src/components/content/ContentGrid.vue";

const QKEY = "CollectionPage";

const props = defineProps({
  collection: { type: String, default: "all-supernatural" },
  name: { type: String, default: "collection" },
  query: { type: Object, default: () => {} },
  view: { type: String, default: "default" },
});

defineOptions({
  name: QKEY,
  async preFetch({ store, currentRoute, ssrContext }) {
    const collectionStore = useCollectionStore(store);
    const { collection: collectionData } = storeToRefs(collectionStore);
    const handle = currentRoute.params.collection || "new-arrivals";
    try {
      const variables = collectionStore.processQuery(handle, currentRoute);
      const queryKey = getQueryKey(QKEY, currentRoute);
      await queryClient.prefetchQuery({
        queryKey,
        queryFn: () => queryCollectionByHandle(variables),
      });
      collectionData.value = queryClient.getQueryData(queryKey);
      if (collectionData.value)
        collectionStore.processCollectionFilters(
          collectionData.value.products.filters
        );
      useDehydrateQueryClient(ssrContext);
    } catch (e) {
      console.error(e);
    }
  },
});

const router = useRouter();
const { t } = i18n.global;
const auraStore = useAuraStore();
const { aura } = storeToRefs(auraStore);
const collectionStore = useCollectionStore();
const { collection: collectionRef, firstProduct } =
  storeToRefs(collectionStore);
const handle = computed(
  () => router.currentRoute.value.params.collection || "new-arrivals"
);
const variables = computed(() =>
  collectionStore.processQuery(handle.value, router.currentRoute.value)
);
const queryKey = computed(() => getQueryKey(QKEY, router.currentRoute.value));
const drawerView = computed(
  () => props.view === "drawer" || props.name === "home"
);

const updateAura = (product) => {
  if (product.node?.images?.edges[0]?.node)
    aura.value = product.node.images.edges[0].node;
};

const { data, error, isError, isFetching, isLoading, isPending, refetch } =
  useQuery({
    queryKey: queryKey.value,
    queryFn: () => queryCollectionByHandle(variables.value),
    initialData: collectionRef.value,
    placeholderData: keepPreviousData,
    enabled: true,
  });

useMeta(() => ({
  meta: {
    description: {
      name: "description",
      content: t("seo.description"),
    },
    ogDescription: {
      property: "og:description",
      content: t("seo.description"),
    },
    ogImage: {
      property: "og:image",
      content: t("seo.image"),
    },
    ogTitle: {
      property: "og:title",
      content: t("seo.title"),
    },
  },
  title: t("seo.title"),
}));

watch(
  firstProduct,
  (product) => {
    if (drawerView.value) return;
    nextTick(() => {
      updateAura(product);
    });
  },
  { deep: true }
);

watch(
  queryKey,
  () => {
    refetch();
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  nextTick(() => {
    if (drawerView.value) return;
    updateAura(firstProduct.value);
  });
});

useQueryErrorHandler(error, isError);
useUpdateStoreFromQuery(data, collectionRef);
useHydrateQueryClient();
</script>
