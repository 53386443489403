import gql from "graphql-tag";
import { basicCollection, collectionImage } from "../fragments/collection";
import { basicProduct, productDetails } from "../fragments/product";
import { basicVariant } from "../fragments/variants";
import { imageCard, imageFullscreen } from "../fragments/image";
import { query } from "./query";

export const collectionProductsByHandle = gql`
  query CollectionProductsByHandle(
    $after: String
    $before: String
    $filters: [ProductFilter!]
    $first: Int
    $handle: String!
    $last: Int
    $reverse: Boolean
    $sortKey: ProductCollectionSortKeys
  ) {
    collection(handle: $handle) {
      ...BasicCollection
      ...CollectionImage
      products(
        after: $after
        before: $before
        filters: $filters
        first: $first
        last: $last
        reverse: $reverse
        sortKey: $sortKey
      ) {
        edges {
          cursor
          node {
            ...BasicProduct
            images(first: 6) {
              edges {
                cursor
                node {
                  ...ImageCard
                }
              }
            }
            ...ProductDetails
            variants(first: 24) {
              edges {
                cursor
                node {
                  ...BasicVariant
                }
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        filters {
          id
          label
          type
          values {
            id
            label
            count
            input
          }
        }
      }
    }
  }
  ${basicCollection}
  ${basicProduct}
  ${basicVariant}
  ${collectionImage}
  ${imageCard}
  ${imageFullscreen}
  ${productDetails}
`;

export const queryCollectionByHandle = async (variables) => {
  try {
    const result = await query(collectionProductsByHandle, variables);
    return result.data.collection;
  } catch (error) {
    console.error("Error executing queryCollectionByHandle:", error);
  }
};
